import { useMelwaysLink } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import { Box, IconHeart, Text, IconArrow, Link } from 'braid-design-system';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import { useBehaviouralCuesFiltersFeatureFlag } from 'src/store/featureFlags/hooks';
import { useSelector } from 'src/store/react';
import { selectAuthenticated, selectRecentSearches } from 'src/store/selectors';

import translations from './.vocab';
import { RecentSearchItem } from './RecentSearches/RecentSearchItem';
import { SavedRecentSearchItem } from './SavedRecentSearchItem';
import useSavedSearchesSearchBar, {
  formatSummary,
} from './SavedSearches/useSavedSearchesSearchBar';
import { MAX_RECENT_SEARCHES } from './constants';

import * as styles from './SavedRecentSearches.css';

export interface SavedRecentSearchesHandle {
  selectNext: () => void;
  selectPrevious: () => void;
  selectItem: () => void;
}

export const SavedRecentSearches = forwardRef<SavedRecentSearchesHandle>(
  ({}, ref) => {
    const { t } = useTranslations(translations);
    const getMelwaysLink = useMelwaysLink();
    const isBehaviouralCuesFiltersEnabled =
      useBehaviouralCuesFiltersFeatureFlag();

    const [highlightedSavedRecentItem, setHighlightedSavedRecentItem] =
      useState<number>(-1);

    const savedRecentItemsRef = useRef<Map<number, HTMLElement | null> | null>(
      null,
    );

    useImperativeHandle(ref, () => ({
      selectNext: () => {
        let newHighlightedIndex = highlightedSavedRecentItem + 1;
        if (newHighlightedIndex > totalSavedRecentItems - 1)
          newHighlightedIndex = 0;
        setHighlightedSavedRecentItem(newHighlightedIndex);
      },
      selectPrevious: () => {
        let newHighlightedIndex = highlightedSavedRecentItem - 1;
        if (newHighlightedIndex < 0)
          newHighlightedIndex = totalSavedRecentItems - 1;
        setHighlightedSavedRecentItem(newHighlightedIndex);
      },
      selectItem: () => {
        const highlightedSearchElement = getSavedRecentItems().get(
          highlightedSavedRecentItem,
        );
        if (highlightedSearchElement) {
          highlightedSearchElement.focus(); // Force the parent input to blur
          highlightedSearchElement.click();
        }
      },
    }));

    // Recent Searches
    const recentSearches = useSelector(selectRecentSearches) || [];
    const recentSearchesToRender = recentSearches.slice(0, MAX_RECENT_SEARCHES);

    // This is for Saved Searches
    const { displayState, savedSearches, totalSavedSearches } =
      useSavedSearchesSearchBar();
    const savedSearchesToRender = savedSearches || [];

    const authenticated = useSelector(selectAuthenticated);
    const savedSearchesDataIsReady = displayState === 'READY';
    const renderSavedSearches =
      authenticated && totalSavedSearches > 0 && savedSearchesDataIsReady;

    const generateSaveSearchUrl = (
      savedSearch: NonNullable<
        ReturnType<typeof useSavedSearchesSearchBar>['savedSearches']
      >[number],
    ): string =>
      getMelwaysLink({
        path: `/jobs?${savedSearch.query.searchQueryString}&savedsearchid=${savedSearch.id}`,
      });

    const seeAllLinkPath = '/my-activity/saved-searches';
    if (recentSearchesToRender.length <= 0 && !renderSavedSearches) {
      return;
    }

    let totalSavedRecentItems = recentSearchesToRender.length;
    if (renderSavedSearches)
      totalSavedRecentItems += savedSearchesToRender.length + 1; // Include +1 for the "See all" link

    function getSavedRecentItems() {
      if (!savedRecentItemsRef.current) {
        savedRecentItemsRef.current = new Map();
      }
      return savedRecentItemsRef.current;
    }

    return (
      <Box
        id="SavedRecentSearches"
        background="surface"
        className={styles.savedRecentPanel}
        boxShadow="large"
        paddingBottom="small"
        paddingTop="small"
        tabIndex={-1}
      >
        {recentSearchesToRender.length > 0 && (
          <Box
            className="recentSearches"
            paddingBottom={renderSavedSearches ? 'xxsmall' : 'none'}
          >
            <Box className={styles.menuItem}>
              <Text weight="strong" size="small" tone="secondary">
                {t('Recent searches')}
              </Text>
            </Box>
            <Box className="searchItemSection">
              {recentSearchesToRender.map((recentSearch, index) => (
                <RecentSearchItem
                  recentSearch={recentSearch}
                  key={`recentSearch-${index}`}
                  index={index}
                  setHighlightedSavedRecentItem={setHighlightedSavedRecentItem}
                  highlightedSavedRecentItem={highlightedSavedRecentItem}
                  ref={(element) => {
                    const savedRecentItems = getSavedRecentItems();
                    if (element) {
                      savedRecentItems.set(index, element);
                    } else {
                      savedRecentItems.set(index, null);
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
        )}

        {renderSavedSearches ? (
          <Box className="savedSearches">
            <Box className={styles.menuItem}>
              <Text weight="strong" size="small" tone="secondary">
                {t('Saved searches')}
              </Text>
            </Box>
            <Box className="searchItemSection">
              {savedSearchesToRender.map((savedSearch, index) => {
                const saveSearchUrl = generateSaveSearchUrl(savedSearch);
                const displayCountLabel = isBehaviouralCuesFiltersEnabled
                  ? savedSearch.newToYouCountLabel
                  : savedSearch.countLabel;

                const overallIndex = recentSearchesToRender.length + index;
                return (
                  <SavedRecentSearchItem
                    searchItem={{
                      label: savedSearch.name,
                      count:
                        displayCountLabel !== '0'
                          ? t('{displayCountLabel} new', {
                              displayCountLabel: displayCountLabel || '',
                            })
                          : undefined,
                      summary: formatSummary(savedSearch.query.parameters),
                      href: saveSearchUrl,
                    }}
                    icon={<IconHeart />}
                    key={`savedSearch-${index}`}
                    index={overallIndex}
                    setHighlightedSavedRecentItem={
                      setHighlightedSavedRecentItem
                    }
                    highlightedSavedRecentItem={highlightedSavedRecentItem}
                    ref={(element) => {
                      const savedRecentItems = getSavedRecentItems();
                      if (element) {
                        savedRecentItems.set(overallIndex, element);
                      } else {
                        savedRecentItems.delete(overallIndex);
                      }
                    }}
                  />
                );
              })}
            </Box>

            <Link
              href={seeAllLinkPath}
              ref={(element) => {
                const savedRecentItems = getSavedRecentItems();
                if (element) {
                  savedRecentItems.set(totalSavedRecentItems - 1, element);
                } else {
                  savedRecentItems.delete(totalSavedRecentItems - 1);
                }
              }}
              aria-label={t('See all ({count})', {
                count: totalSavedSearches.toString(),
              })}
            >
              <Box
                className={{
                  [styles.menuItem]: true,
                  [styles.searchItemHighlighted]:
                    highlightedSavedRecentItem === totalSavedRecentItems - 1,
                }}
                onMouseEnter={() => {
                  setHighlightedSavedRecentItem(totalSavedRecentItems - 1);
                }}
                onMouseLeave={() => {
                  setHighlightedSavedRecentItem(-1);
                }}
              >
                <Text size="small" weight="regular" baseline={false}>
                  <Box
                    component="span"
                    className={{ [styles.seeAllLink]: true }}
                    marginRight="xxsmall"
                  >
                    {t('See all ({count})', {
                      count: totalSavedSearches.toString(),
                    })}
                  </Box>
                  <IconArrow direction="right" />
                </Text>
              </Box>
            </Link>
          </Box>
        ) : null}
      </Box>
    );
  },
);
