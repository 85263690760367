import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'asia-4',
  defaultLocale: 'id-ID',
  availableLocales: ['en-ID', 'id-ID'],
  timedBanners: {},
  zoneFeatures: {
    NUDGE_ENABLED: false,
    HOMEPAGE_BANNER_TYPE: 'multiple-asia',
    HOMEPAGE_LHS_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    HOMEPAGE_RHS_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    SEARCH_PAGE_SIZE: 32,
    SHOW_FLOATING_SAVE_SEARCH: false,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: true,
    LMIS_ENABLED: true,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: false,
    REMOTE_SEARCH_FILTER_NEW_LABEL: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    SERP_JOBCARD_INFO_DENSITY_1: false,
    MATCHED_QUALITIES: false,
    ENTRY_LEVEL_BADGE: true,
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_keVW4JKlS0jBJLPfSRNUJnhhtElmK541'
    : 'key_test_igQ77GMmQ1itGVIiMGXzojmavAcg9pio',
  GPT_ACCOUNT_ID: '23210488134',
} as AppConfig;
