import type {
  ExperimentConfig,
  FeatureType,
} from '@seek/candidate-feature-flag';

export const REFINE_BAR_V2_FEATURE_FLAG =
  'experiment-dynamic-pills-v2-sticky-search-bar';
export const DynamicPillsV2 = 'dynamic-pills-v2';
export const StickySearchBar = 'sticky-search-bar';
export const RefineBarV2 = 'refine-bar-v2';
const refineBarV2Experiment = {
  key: REFINE_BAR_V2_FEATURE_FLAG,
  trackingNumber: 65,
  title: 'Dynamic Pills v2 Sticky Search Bar',
  type: 'string',
  variations: [
    'control',
    DynamicPillsV2,
    StickySearchBar,
    RefineBarV2,
  ] as const,
} satisfies ExperimentConfig;
export type RefineBarV2Experiment = FeatureType<typeof refineBarV2Experiment>;

export const AUTO_FOCUS_WHERE_FEATURE_FLAG = 'experiment-autofocus-where-field';
const autoFocusWhereExperiment = {
  key: AUTO_FOCUS_WHERE_FEATURE_FLAG,
  trackingNumber: 65,
  title: 'Auto Focus Where Field',
  type: 'boolean',
} satisfies ExperimentConfig;
export type AutoFocusWhereExperiment = FeatureType<
  typeof autoFocusWhereExperiment
>;

export const SAVED_AND_RECENT_SEARCHES_FEATURE_FLAG =
  'experiment-saved-and-recent-searches';
const savedAndRecentSearchesExperiment = {
  key: SAVED_AND_RECENT_SEARCHES_FEATURE_FLAG,
  trackingNumber: 65,
  title: 'Saved And Recent Searches',
  type: 'string',
  variations: ['control', 'saved-and-recent-searches'] as const,
} satisfies ExperimentConfig;
export type SavedAndRecentSearchesExperiment = FeatureType<
  typeof savedAndRecentSearchesExperiment
>;

export const BEHAVIOURAL_CUES_FILTERS_FEATURE_FLAG =
  'experiment-behavioural-cues-filters';
export const BehaviouralCuesFilters = 'bcuesFilters';
const behaviouralCuesFiltersExperiment = {
  key: BEHAVIOURAL_CUES_FILTERS_FEATURE_FLAG,
  trackingNumber: 65,
  title: 'Behavioural Cues Filters',
  type: 'string',
  variations: ['control', BehaviouralCuesFilters] as const,
} satisfies ExperimentConfig;
export type BehaviouralCuesFiltersExperiment = FeatureType<
  typeof behaviouralCuesFiltersExperiment
>;

export const LOGGED_OUT_HOMEPAGE_UPLIFT_FEATURE_FLAG =
  'experiment-logged-out-homepage-uplift';
const loggedOutHomepageUpliftExperiment = {
  key: LOGGED_OUT_HOMEPAGE_UPLIFT_FEATURE_FLAG,
  trackingNumber: 69,
  title: ' Logged out homepage uplift',
  type: 'string',
  variations: ['control', 'with-selling-points', 'with-multi-ctas'] as const,
} satisfies ExperimentConfig;
export type LoggedOutHomepageUpliftExperiment = FeatureType<
  typeof loggedOutHomepageUpliftExperiment
>;

export const SERP_JOB_CARD_REDESIGN_FEATURE_FLAG =
  'experiment-serp-job-card-redesign';
export const JobCardRedesign = 'jobCardRedesign';
const serpJobCardRedesignExperiment = {
  key: SERP_JOB_CARD_REDESIGN_FEATURE_FLAG,
  trackingNumber: 65,
  title: 'SERP Job Card Redesign',
  type: 'string',
  variations: ['control', JobCardRedesign] as const,
} satisfies ExperimentConfig;
export type SERPJobCardRedesignExperiment = FeatureType<
  typeof serpJobCardRedesignExperiment
>;

export const SEARCH_QUERY_CORRECTION_FEATURE_FLAG =
  'experiment-search-query-correction';
const searchQueryCorrectionExperiment = {
  key: SEARCH_QUERY_CORRECTION_FEATURE_FLAG,
  trackingNumber: 65,
  title: 'Search Query Correction',
  type: 'string',
  variations: ['control', 'search-query-correction'] as const,
} satisfies ExperimentConfig;
export type SearchQueryCorrectionExperiment = FeatureType<
  typeof searchQueryCorrectionExperiment
>;

export const experimentsToFetch: ExperimentConfig[] = [
  refineBarV2Experiment,
  autoFocusWhereExperiment,
  savedAndRecentSearchesExperiment,
  loggedOutHomepageUpliftExperiment,
  serpJobCardRedesignExperiment,
  behaviouralCuesFiltersExperiment,
  searchQueryCorrectionExperiment,
];
