import type { ApolloError } from '@apollo/client';
import { datadogLogs, type StatusType } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { brandAndCountryFromZone } from '@seek/audience-zones';
import { parse } from 'cookie';

import {
  appName,
  datadogBrowserLogsIsEnabled,
  datadogBrowserLogsMetadata,
  datadogRumIsEnabled,
  environment,
  version,
} from 'src/config';

import type { TSeekExperiments } from '../../../store/experiments/types.ts';
import type { FeatureFlagsState } from '../../../store/featureFlags/types.ts';

import { beforeSend } from './beforeSend';
import { scrubSensitiveData } from './utils';

export type InputProps = Record<string, any>;

export interface BrowserLogExtras {
  kind?: 'clientError' | 'serverError';
  name?: string;
  input?: InputProps;
}

export const initBrowserLog = (): void => {
  if (datadogBrowserLogsIsEnabled) {
    // for more info about the configuration:
    // https://docs.datadoghq.com/logs/log_collection/javascript/#configuration
    datadogLogs.init({
      service: appName,
      clientToken: JSON.parse(datadogBrowserLogsMetadata)?.clientToken,
      site: 'datadoghq.com',
      forwardErrorsToLogs: false,
      sessionSampleRate: 20,
      env: environment,
      version,
      beforeSend,
    });
  }
};

const getExtrasDetails = (
  extras: BrowserLogExtras,
  error?: Error | ApolloError,
) => {
  const { zone, site } = window.SEEK_APP_CONFIG;
  const { name, kind, input } = extras;
  const [brand, country] = (zone && brandAndCountryFromZone(zone)) || [];
  return {
    site,
    name,
    kind,
    brand,
    country,
    zone,
    input: input && scrubSensitiveData(input),
    seek_test: Boolean(window.SEEK_TEST),
    seek_sessionID: parse(document.cookie).JobseekerSessionId,
    raw: { ...error },
  };
};

export const setUserId = (userId: string): void => {
  if (datadogBrowserLogsIsEnabled) {
    datadogLogs.setUser({ id: userId });
  }
  if (datadogRumIsEnabled) {
    datadogRum.setUser({ id: userId });
  }
};

const getValue = (value: any): string => {
  if (value && typeof value === 'object') {
    return JSON.stringify(value);
  }

  return value;
};

export const setFeatureFlags = (
  features: FeatureFlagsState | TSeekExperiments,
): void => {
  Object.entries(features).forEach(([key, value]) => {
    const experimentValue = getValue(value);

    if (datadogBrowserLogsIsEnabled) {
      datadogLogs.setGlobalContext({ [`featureFlag.${key}`]: experimentValue });
    }

    if (datadogRumIsEnabled) {
      datadogRum.addFeatureFlagEvaluation(key, experimentValue);
    }
  });
};

export const log = (
  level: StatusType,
  message: string,
  error: Error | ApolloError,
  extras: BrowserLogExtras = {},
): void => {
  const extrasDetail = getExtrasDetails(extras, error);

  if (datadogBrowserLogsIsEnabled) {
    const datadogErrorMapping = error
      ? { 'error.kind': error?.name, 'error.stack': error?.stack }
      : {};

    datadogLogs.logger[level](
      message,
      {
        ...datadogErrorMapping,
        extras: extrasDetail,
      },
      error,
    );
  } else {
    switch (level) {
      case 'error': {
        // eslint-disable-next-line no-console
        console.error(extrasDetail, error);
        break;
      }
      case 'warn': {
        // eslint-disable-next-line no-console
        console.warn(extrasDetail, error);
        break;
      }
      case 'info': {
        // eslint-disable-next-line no-console
        console.log(extrasDetail, error);
        break;
      }
      default: {
        break;
      }
    }
  }
};
