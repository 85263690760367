import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { environment } from 'src/config';

import { log, type BrowserLogExtras } from './browser-logs';

export type ErrorAndMetadata = {
  err?: Record<string, any>;
} & BrowserLogExtras;

const sendLog = (
  level: 'info' | 'warn' | 'error',
  errorAndMetadata: ErrorAndMetadata,
  message?: string,
) => {
  const messageToLog = message || `unspecified ${level} message`;
  const { err } = errorAndMetadata;

  if (environment !== 'production') {
    /* eslint-disable no-console */
    console[level](message);
  }

  log(level, messageToLog, err as Error, {
    kind: 'clientError',
    ...errorAndMetadata,
  });
};

const logger = {
  trace() {},
  debug() {},
  info(errorAndMetadata: ErrorAndMetadata, message?: string) {
    sendLog('info', errorAndMetadata, message);
  },
  warn(errorAndMetadata: ErrorAndMetadata, message?: string) {
    sendLog('warn', errorAndMetadata, message);
  },
  error(errorAndMetadata: ErrorAndMetadata, message?: string) {
    sendLog('error', errorAndMetadata, message);
  },
  setUserId(userId: string) {
    datadogLogs.setUser({ id: userId });
    datadogRum.setUser({ id: userId });
  },
  setFeatureFlags(features: Record<string, any>) {
    Object.entries(features).forEach(([key, value]) => {
      datadogRum.addFeatureFlagEvaluation(key, value);
      datadogLogs.setGlobalContext({ [key]: value });
    });
  },
  /* eslint-enable no-console*/
};

export default logger;
