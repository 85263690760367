import { Box, IconClear, IconSearch, Strong, Text } from 'braid-design-system';

import { useFeatureFlagRefineBarV2Experiment } from 'src/store/featureFlags/hooks';

import * as styles from './DismissableTooltip.css';

interface TooltipProps {
  handleClose: () => void;
  message: string;
  animateTooltip?: boolean;
}

export const DismissableTooltip = ({
  handleClose,
  message,
  animateTooltip,
}: TooltipProps) => {
  const { dynamicPillsV2, stickySearchBar, refineBarV2 } =
    useFeatureFlagRefineBarV2Experiment();
  const isRefineBarV2Experiment =
    dynamicPillsV2 || stickySearchBar || refineBarV2;

  return (
    <Box
      position="absolute"
      paddingTop={{ mobile: 'none', tablet: 'medium' }}
      zIndex="dropdown"
      width="full"
      className={{
        [styles.tooltipContainer]: true,
        [styles.tooltipAnimation]: animateTooltip,
        [styles.tooltipWidthReduced]: isRefineBarV2Experiment,
      }}
      borderRadius="large"
      boxShadow="medium"
    >
      <Box
        borderRadius="large"
        paddingX="small"
        paddingY="xsmall"
        background="info"
        display="flex"
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Box>
            <IconSearch tone="secondary" />
          </Box>
          <Box paddingX="xsmall">
            <Text size="xsmall" baseline={false}>
              <Strong>{message}</Strong>
            </Text>
          </Box>
        </Box>
        <Box cursor="pointer" onClick={handleClose}>
          <IconClear />
        </Box>
        <Box className={styles.arrow} />
      </Box>
    </Box>
  );
};
