import { useEffect, useMemo, useState } from 'react';

import { useZoneFeatures } from 'src/config/appConfig';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useCandidateDetails } from 'src/modules/hooks/useCandidateDetailsQuery';
import { parseCookies } from 'src/modules/safe-cookies';
import {
  experimentsAfterAuthIntialised,
  FEATURE_EXPERIMENTS_AFTER_AUTH,
} from 'src/store/experiments';
import { mapToSeekExperiments } from 'src/store/experiments/experimentHelpers';
import { updateFeatureFlagsAfterAuth } from 'src/store/featureFlags';
import { useDispatch, useSelector } from 'src/store/react';
import { useMelwaysZone } from 'src/utils/melwaysHelper';

import { logger } from '../../modules/logger';

export const useInitialiseSignedInExperiments = () => {
  const dispatch = useDispatch();

  const [isExperimentsInitialised, setIsExperimentsInitialised] =
    useState(false);

  const cookies = useMemo(() => parseCookies(), []);
  const zone = useMelwaysZone();
  const { id: seekerId } = useCandidateDetails();
  const zoneFeatures = useZoneFeatures();
  const experiments = useSelector((state) => state.experiments);
  const analyticsFacade = useAnalyticsFacade();

  useEffect(() => {
    if (!isExperimentsInitialised && seekerId) {
      // The following will push the new user group to `experiments` state.
      const seekExperimentsAfterAuth = mapToSeekExperiments({
        userId: String(seekerId),
        featureExperiments: FEATURE_EXPERIMENTS_AFTER_AUTH,
        shouldExcludeFromExperiment: false,
        zone,
      });
      dispatch(experimentsAfterAuthIntialised(seekExperimentsAfterAuth));

      // Update feature flags after user details are fetched and updated the `experiments` state.
      dispatch(
        updateFeatureFlagsAfterAuth(
          cookies,
          seekExperimentsAfterAuth,
          zoneFeatures,
        ),
      );

      setIsExperimentsInitialised(true);
    }
  }, [
    cookies,
    dispatch,
    isExperimentsInitialised,
    seekerId,
    zone,
    zoneFeatures,
  ]);

  useEffect(() => {
    if (isExperimentsInitialised) {
      analyticsFacade.registerExperiments(experiments);
      if (logger.setFeatureFlags) {
        logger.setFeatureFlags(experiments);
      }
    }
  }, [analyticsFacade, experiments, isExperimentsInitialised]);
};
