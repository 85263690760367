import 'braid-design-system/reset';

import { datadogRum } from '@datadog/browser-rum';
import { setup as setupMetrics } from '@seek/metrics-js';
import get from 'lodash/get';
import { loadableReady } from 'sku/@loadable/component';
import smoothscroll from 'smoothscroll-polyfill';
import VError from 'verror';

import {
  datadogEnvironment,
  datadogRumApplicationMetadata,
  datadogRumIsEnabled,
  datadogService,
  datadogVersion,
  environment,
  metricsHost,
  metricsIsEnabled,
  metricsPrefix,
  traceSampleRate,
  version,
} from 'src/config';
// @ts-expect-error: non-ts file
import ariaClick from 'src/modules/aria-click';
import { isMobileUserAgent } from 'src/modules/chalice-user-agent/device-detect';
import { setupLogger } from 'src/modules/logger';
import clientLogger from 'src/modules/logger/client';

import clientRender from './client-render';
import getCountryFromZone from './config/utils/getCountryFromZone';
import {
  initBrowserLog,
  setFeatureFlags,
  setUserId,
} from './modules/logger/browser-logs';
import pathnameToPageName from './pathnameToPageName';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/polyfill';

declare global {
  interface Window {
    SEEK_APOLLO_DATA: any;
    SEEK_TEST: boolean;
  }
}

const zone = window.SEEK_APP_CONFIG.zone;

smoothscroll.polyfill();
setupLogger({ ...clientLogger, setFeatureFlags, setUserId });
ariaClick(document);
setupMetrics({
  host: metricsHost,
  isEnabled: metricsIsEnabled,
  prefix: metricsPrefix,
  globalTags: [
    `version:${version}`,
    `locale:${getCountryFromZone(zone)}`,
    `environment:${environment}`,
    'platform:client',
    `device-type:${
      isMobileUserAgent(window.navigator.userAgent) ? 'mobile' : 'desktop'
    }`,
    'service_name:chalice',
    `page:${pathnameToPageName(window.location.pathname)}`,
  ],
});

// catch promise unhandled rejections & errors
window.addEventListener('unhandledrejection', (e) => {
  e.preventDefault();
  const err = get(e, 'detail.reason') || get(e, 'detail') || e;

  if (err instanceof Error) {
    throw new VError(err, 'Unhandled rejection error');
  }
});

if (datadogRumIsEnabled) {
  datadogRum.init({
    applicationId: JSON.parse(datadogRumApplicationMetadata)?.applicationId,
    clientToken: JSON.parse(datadogRumApplicationMetadata)?.clientToken,
    site: 'datadoghq.com',
    service: datadogService,
    env: datadogEnvironment,
    version: datadogVersion,
    sessionSampleRate: traceSampleRate * 100, // percent
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    telemetrySampleRate: 0,
    enableExperimentalFeatures: ['feature_flags'],
    allowedTracingUrls: [
      /https:\/\/.*\.seek\.com\.au\/graphql/,
      /https:\/\/.*\.seek\.co\.nz\/graphql/,
      /https:\/\/.*\.jobsdb\.com\/graphql/,
      /https:\/\/.*\.jobstreet\.com\/graphql/,
      /https:\/\/discover-career-feed-genai-api\.(?:staging\.)?cloud\.seek\.com\.au/,
    ],
  });
}

initBrowserLog();

loadableReady(() => {
  clientRender(document.getElementById('app'));
});
