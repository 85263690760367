import type { CookieType } from 'src/types/cookie';

export const UPDATE_FEATURE_FLAGS = 'UPDATE_FEATURE_FLAGS';
export const OVERRIDE_FEATURE_FLAGS = 'OVERRIDE_FEATURE_FLAGS';

export interface FeatureFlagsState {
  branchBannerPreview: boolean;
  isBranchEnabledFlag: boolean;
  showHomepageBanner: boolean;
  showFloatingSaveSearch: boolean;
  autoSelectSplitViewFirstJob: boolean;
  showMarketingAdSplitView: boolean;
  loggedOutRecs: boolean;
  remoteSearchFilter: boolean;
  remoteSearchFilterNewLabel: boolean;
  keywordAutosuggestV2: boolean;
  serpJobCardInfoDensity1: boolean;
  hideCompanyLogo: boolean;
  matchedQualities: boolean;
  entryLevelBadge: boolean;
  homepageRecsBadgingStrongApplicant: boolean;
  homepageRecsBadgingExpiringSoonEarlyApplicant: boolean;
  homepageJobCardDensity: boolean;
  homepageGenAIChat: boolean;
  homepageGenAIChatAltModel: boolean;
  competitivePlacementEmbeddedJdv: boolean;
  competitivePlacement: boolean;
  overrides?: CookieType;
}

export interface UpdateFeatureFlagsAction {
  type: typeof UPDATE_FEATURE_FLAGS;
  payload: {
    dynamicFlags: Record<string, any>;
  };
}

export interface FeatureFlagsOverrideAction {
  type: typeof OVERRIDE_FEATURE_FLAGS;
  payload: {
    overrides: CookieType;
  };
}

export type FeatureFlagsAction =
  | UpdateFeatureFlagsAction
  | FeatureFlagsOverrideAction;

export type TFeatureFlagNames = keyof FeatureFlagsState;
