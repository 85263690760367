import type { TSeekExperiments } from '../../store/experiments/types.ts';
import type { FeatureFlagsState } from '../../store/featureFlags/types.ts';

import type { ErrorAndMetadata } from './client';

type Log = (errorAndMetadata: ErrorAndMetadata, message?: string) => void;

const noop: Log = () => {};

interface Logger {
  trace: Log;
  debug: Log;
  info: Log;
  warn: Log;
  error: Log;
  setUserId?: (userId: string) => void;
  setFeatureFlags?: (
    featureFlags: FeatureFlagsState | TSeekExperiments,
  ) => void;
}

export let logger: Logger = {
  trace: noop,
  debug: noop,
  info: noop,
  warn: noop,
  error: noop,
  setUserId: undefined,
  setFeatureFlags: undefined,
};

export const setupLogger = (logProvider: Logger) => {
  logger = logProvider;
};
