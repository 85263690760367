import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'anz-1',
  defaultLocale: 'en-AU',
  availableLocales: ['en-AU'],
  timedBanners: {},
  zoneFeatures: {
    LMIS_ENABLED: true,
    NUDGE_ENABLED: true,
    SEARCH_PAGE_SIZE: 22,
    SHOW_FLOATING_SAVE_SEARCH: true,
    SHOW_JOBCARD_TEASER_IN_SPLITVIEW: true,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: true,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: true,
    REMOTE_SEARCH_FILTER_NEW_LABEL: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    SERP_JOBCARD_INFO_DENSITY_1: false,
    MATCHED_QUALITIES: false,
    ENTRY_LEVEL_BADGE: true,
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_hpVf26efkYxTQTj88NJzZpchsqglHGRe'
    : 'key_test_khOf96nlb9tQIPl47VZiEcbpszklPUNw',
  GPT_ACCOUNT_ID: '23030987465',
} as AppConfig;
